// extracted by mini-css-extract-plugin
export var TechStacksMobileOutSourcingWeb = "TechStacksMobile-module--TechStacksMobileOutSourcingWeb--1684e";
export var cardBg = "TechStacksMobile-module--cardBg--f1c17";
export var cards = "TechStacksMobile-module--cards--69bc9";
export var cardsContent = "TechStacksMobile-module--cardsContent--34b36";
export var description = "TechStacksMobile-module--description--51d97";
export var heading = "TechStacksMobile-module--heading--0146a";
export var iconssCard = "TechStacksMobile-module--iconssCard--76409";
export var nav = "TechStacksMobile-module--nav--f00b6";
export var navItem = "TechStacksMobile-module--nav-item--75518";
export var navbarBlock = "TechStacksMobile-module--navbarBlock--2839c";
export var tabData = "TechStacksMobile-module--tabData--5c5c4";
export var tech = "TechStacksMobile-module--tech--15b5b";
export var techIcon = "TechStacksMobile-module--techIcon--c6b6c";
export var techImg = "TechStacksMobile-module--techImg--f77d1";
export var technologyIcon = "TechStacksMobile-module--technologyIcon--dfde5";