// extracted by mini-css-extract-plugin
export var arrowPics1 = "Effective-module--arrowPics1--89653";
export var arrowPics2 = "Effective-module--arrowPics2--2a037";
export var arrowPics3 = "Effective-module--arrowPics3--49c3f";
export var arrowPics4 = "Effective-module--arrowPics4--307aa";
export var arrowPics5 = "Effective-module--arrowPics5--8aa4b";
export var bgImage = "Effective-module--bgImage--1e6b5";
export var borderStock = "Effective-module--borderStock--9d3df";
export var dottedLine1 = "Effective-module--dottedLine1--f00c5";
export var effectiveOutSource = "Effective-module--effectiveOutSource--6443b";
export var giffBg = "Effective-module--giffBg--b0836";
export var giffBg2 = "Effective-module--giffBg2--471fe";
export var giffBg3 = "Effective-module--giffBg3--9dee0";
export var giffBg4 = "Effective-module--giffBg4--0d912";
export var giffBg5 = "Effective-module--giffBg5--5c39c";
export var giffBg6 = "Effective-module--giffBg6--4f885";
export var imageGiffs = "Effective-module--imageGiffs--b09ac";
export var lottieImages1 = "Effective-module--lottieImages1--55a00";
export var lottieImages2 = "Effective-module--lottieImages2--18275";
export var lottieImages3 = "Effective-module--lottieImages3--433ce";
export var lottieImages4 = "Effective-module--lottieImages4--4858d";
export var lottieImages5 = "Effective-module--lottieImages5--d3388";
export var lottieImages6 = "Effective-module--lottieImages6--199ac";
export var mainDottedLeft1 = "Effective-module--mainDottedLeft1--57428";
export var mainDottedLeft2 = "Effective-module--mainDottedLeft2--564f6";
export var mainDottedLeft3 = "Effective-module--mainDottedLeft3--1d946";
export var mainDottedRight1 = "Effective-module--mainDottedRight1--e1e99";
export var mainDottedRight2 = "Effective-module--mainDottedRight2--c36ce";
export var mainDottedRight3 = "Effective-module--mainDottedRight3--8bb3a";
export var overlay = "Effective-module--overlay--d7c2f";
export var processData = "Effective-module--processData--a8003";
export var processData2 = "Effective-module--processData2--20011";
export var processData3 = "Effective-module--processData3--e2512";
export var processDes = "Effective-module--processDes--6d20d";
export var processDetails = "Effective-module--processDetails--cea37";
export var processes = "Effective-module--processes--c3fc3";
export var rightProcess = "Effective-module--rightProcess--01611";
export var rightProcessData = "Effective-module--rightProcessData--8d6c3";
export var rightProcessData2 = "Effective-module--rightProcessData2--d65da";
export var rightProcessData3 = "Effective-module--rightProcessData3--1ed5a";
export var rightProcessDes = "Effective-module--rightProcessDes--607fc";
export var stagegyDescription = "Effective-module--stagegyDescription--c6414";
export var strategyHeading = "Effective-module--strategyHeading--84db7";