// extracted by mini-css-extract-plugin
export var crossImg = "RightOutSourcing-module--crossImg--1d874";
export var description = "RightOutSourcing-module--description--4de14";
export var description2 = "RightOutSourcing-module--description2--4c249";
export var faqButton = "RightOutSourcing-module--faqButton--ca954";
export var faqsNum = "RightOutSourcing-module--faqsNum--2c462";
export var faqsSub = "RightOutSourcing-module--faqsSub--5eb92";
export var head = "RightOutSourcing-module--head--68e9e";
export var headr = "RightOutSourcing-module--headr--deec0";
export var quesColor = "RightOutSourcing-module--quesColor--082cc";
export var quesColorSelected = "RightOutSourcing-module--quesColorSelected--5a682";
export var reverse = "RightOutSourcing-module--reverse--ddc38";
export var sectionOutsourcingWeb = "RightOutSourcing-module--sectionOutsourcingWeb--70512";
export var serial = "RightOutSourcing-module--serial--c8432";
export var serial2 = "RightOutSourcing-module--serial2--6bbcc";
export var textColor = "RightOutSourcing-module--textColor--e8413";