import React from "react"
import * as styles from "./Effective.module.scss"
import Container from "react-bootstrap/Container"
import bgimg from "../../images/web-application/mainimg.png"
import overlay from "../../images/web-application/overlayimg.png"
import giffbg from "../../images/web-application/giffbg.png"
import process from "../../images/web-application/up.svg"
import borderStock from "../../images/web-application/borderStroke.svg"
import mainDottedRight from "../../images/outsourcing/dottedlineMainRight.svg"
import mainDottedLeft from "../../images/outsourcing/dottedMainLeft.svg"

const Effective = ({ strapiData }) => {
  const lottieImagesData = [
    {
      lottie:
        "https://invozone-backend.s3.amazonaws.com/start_of_development_a6ec584119.json",
    },
    {
      lottie:
        "https://invozone-backend.s3.amazonaws.com/onboarding_7a0c16c0db.json",
    },
    {
      lottie:
        "https://invozone-backend.s3.amazonaws.com/explore_the_options_3b9ca7c140.json",
    },
    {
      lottie:
        "https://invozone-backend.s3.amazonaws.com/deployment_and_lanch_02153d1884.json",
    },

    {
      lottie:
        "https://invozone-backend.s3.amazonaws.com/ideation_and_stragy_5c5b8ae02c.json",
    },
    {
      lottie:
        "https://invozone-backend.s3.amazonaws.com/maintanince_9b9ca2f709.json",
    },
  ]
  const arrowImages = [
    {
      arrowImg: process,
    },
    {
      arrowImg: process,
    },
    {
      arrowImg: process,
    },
    {
      arrowImg: process,
    },
    {
      arrowImg: process,
    },
  ]
  return (
    <React.Fragment>
      <div className={styles.effectiveOutSource}>
        <Container>
          <h2
            className={styles.strategyHeading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.stagegyDescription}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div
            className={` align-items-center justify-content-between d-flex ${styles.processDetails}`}
          >
            <div className={`${styles.processes}`}>
              <h3 className={`${styles.processData}`}>
                01 -Discover Your Needs
              </h3>
              <p className={`${styles.processDes}`}>
                First of all, we discover your needs and what tech stacks you
                plan to work with.
              </p>
              <h3 className={`${styles.processData2}`}>
                02 - Explore The Options
              </h3>
              <p className={`${styles.processDes}`}>
                Once you have defined your vision or needs, we offer you a list
                of qualified full-stack experts.
              </p>
              <h3 className={`${styles.processData3}`}>03 - Onboarding</h3>
              <p className={`${styles.processDes}`}>
                Now that you have the right match, we engage you with the
                outsourcing talent you’ve chosen.
              </p>
            </div>
            <div className={`${styles.imageGiffs}`}>
              <img src={bgimg} alt="" className={styles.bgImage} />
              <img src={overlay} alt="" className={styles.overlay} />
              <img src={giffbg} alt="" className={styles.giffBg} />
              <img src={giffbg} alt="" className={styles.giffBg2} />
              <img src={giffbg} alt="" className={styles.giffBg3} />
              <img src={giffbg} alt="" className={styles.giffBg4} />
              <img src={giffbg} alt="" className={styles.giffBg5} />
              <img src={giffbg} alt="" className={styles.giffBg6} />
              {arrowImages &&
                arrowImages?.map((a, r) => (
                  <div className={styles[`arrowPics${r + 1}`]} key={r}>
                    <img src={a.arrowImg} alt="" />
                  </div>
                ))}
              <img src={borderStock} alt="" className={styles.borderStock} />
              {lottieImagesData &&
                lottieImagesData?.map((e, i) => (
                  <div className={styles[`lottieImages${i + 1}`]} key={i}>
                    <lottie-player
                      autoplay
                      loop
                      src={e?.lottie}
                      style={{ height: "60px", width: "60px", margin: "0px" }}
                    />
                  </div>
                ))}
            </div>
            <div>
              <img
                src={mainDottedRight}
                alt=""
                className={styles.mainDottedRight1}
              />
              <img
                src={mainDottedLeft}
                alt=""
                className={styles.mainDottedLeft1}
              />
              <img
                src={mainDottedRight}
                alt=""
                className={styles.mainDottedRight2}
              />
              <img
                src={mainDottedLeft}
                alt=""
                className={styles.mainDottedLeft2}
              />
              <img
                src={mainDottedRight}
                alt=""
                className={styles.mainDottedRight3}
              />
              <img
                src={mainDottedLeft}
                alt=""
                className={styles.mainDottedLeft3}
              />
            </div>
            <div className={`${styles.rightProcess}`}>
              <h3 className={`${styles.rightProcessData}`}>
                06 - Overhauling Support
              </h3>
              <p className={`${styles.rightProcessDes}`}>
                We take responsibility for necessary updates and maintenance
                that the web apps could use after development.
              </p>
              <h3 className={`${styles.rightProcessData2}`}>
                05 - Deployment Support
              </h3>
              <p className={`${styles.rightProcessDes}`}>
                To guarantee your solution is bug-free and fulfills the initial
                criteria, we do thorough testing when it is ready.
              </p>
              <h3 className={`${styles.rightProcessData3}`}>
                04 - Start Of Development
              </h3>
              <p className={`${styles.rightProcessDes}`}>
                Once all the contractual formalities are settled, the
                development process takes place.
              </p>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Effective
