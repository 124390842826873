import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import "./TechStacksMobile.scss"
import * as styles from "./TechStacksMobile.module.scss"

const TechStacksMobileOutSource = ({ strapiData }) => {
  const data = [
    {
      title: "Libraries",
      tech: [
        {
          name: "jQuery",
          icon: "https://invozone-backend.s3.amazonaws.com/jquery_ec723c935f.svg",
        },
        {
          name: "React",
          icon: "https://invozone-backend.s3.amazonaws.com/react_57ed18c6ed.svg",
        },
        {
          name: "Angular",
          icon: "https://invozone-backend.s3.amazonaws.com/angular_9f983560c4.svg",
        },
        {
          name: "Vue.js",
          icon: "https://invozone-backend.s3.amazonaws.com/vue_fc7995e57d.svg",
        },
        {
          name: "Bootstrap",
          icon: "https://invozone-backend.s3.amazonaws.com/bootstrap_4b57543904.svg",
        },
        {
          name: "Backbone.js",
          icon: "https://invozone-backend.s3.amazonaws.com/backbone_js_e963893eb3.svg",
        },
        {
          name: "Ember.js",
          icon: "https://invozone-backend.s3.amazonaws.com/ember_js_c658b7b960.svg",
        },
        {
          name: "D3.js",
          icon: "https://invozone-backend.s3.amazonaws.com/d3_js_8fc7560996.svg",
        },
        {
          name: "Redux",
          icon: "https://invozone-backend.s3.amazonaws.com/redux_1ebda0b1fc.svg",
        },
        {
          name: "Lodash",
          icon: "https://invozone-backend.s3.amazonaws.com/lodash_8351739248.svg",
        },
        {
          name: "Styled Component",
          icon: "https://invozone-backend.s3.amazonaws.com/styled_component_4fab00ca49.svg",
        },
        {
          name: "Emotion",
          icon: "https://invozone-backend.s3.amazonaws.com/emotion_2cc14a0dc9.svg",
        },
      ],
    },
    {
      title: "Frontend",
      tech: [
        {
          name: "HTML",
          icon: "https://invozone-backend.s3.amazonaws.com/html_93186a6d4d.svg",
        },
        {
          name: "CSS",
          icon: "https://invozone-backend.s3.amazonaws.com/css_9bb82190d2.svg",
        },
        {
          name: "JavaScript",
          icon: "https://invozone-backend.s3.amazonaws.com/javascript_1d5a458fb2.svg",
        },
        {
          name: "React.js",
          icon: "https://invozone-backend.s3.amazonaws.com/react_57ed18c6ed.svg",
        },
        {
          name: "Angular",
          icon: "https://invozone-backend.s3.amazonaws.com/angular_9f983560c4.svg",
        },
        {
          name: "Vue.js",
          icon: "https://invozone-backend.s3.amazonaws.com/vue_js_1fc7d4170d.svg",
        },
        {
          name: "Bootstrap",
          icon: "https://invozone-backend.s3.amazonaws.com/bootstrap_4b57543904.svg",
        },
        {
          name: "Materialize",
          icon: "https://invozone-backend.s3.amazonaws.com/materialize_84b39e328a.svg",
        },
        {
          name: "Sass",
          icon: "https://invozone-backend.s3.amazonaws.com/sass_609cb8db74.svg",
        },
        {
          name: "Less",
          icon: "https://invozone-backend.s3.amazonaws.com/less_215065fd23.svg",
        },
        {
          name: "Webpack",
          icon: "https://invozone-backend.s3.amazonaws.com/webpack_7ecff4825f.svg",
        },
        {
          name: "Tailwind",
          icon: "https://invozone-backend.s3.amazonaws.com/tailwind_css_60274236cf.svg",
        },
        {
          name: "Substrate",
          icon: "https://invozone-backend.s3.amazonaws.com/substrate_e9c0df6752.svg",
        },
        {
          name: "Graphql",
          icon: "https://invozone-backend.s3.amazonaws.com/graphql_64900f0cb9.svg",
        },
        {
          name: "Laravel",
          icon: "https://invozone-backend.s3.amazonaws.com/laravel_2c5aad2072.svg",
        },
        {
          name: "WordPress",
          icon: "https://invozone-backend.s3.amazonaws.com/wordpress_40aceb5a26.svg",
        },
      ],
    },
    {
      title: "Backend",
      tech: [
        {
          name: "Node.js",
          icon: "https://invozone-backend.s3.amazonaws.com/node_js_38bc3f6376.svg",
        },
        {
          name: "Python",
          icon: "https://invozone-backend.s3.amazonaws.com/python_52f90a111a.svg",
        },
        {
          name: "Ruby on Rails",
          icon: "https://invozone-backend.s3.amazonaws.com/ruby_on_rails_7593e97844.svg",
        },
        {
          name: "PHP",
          icon: "https://invozone-backend.s3.amazonaws.com/php_e68d6a6582.svg",
        },
        {
          name: "Express.js",
          icon: "https://invozone-backend.s3.amazonaws.com/express_js_656298a757.svg",
        },
        {
          name: "Django",
          icon: "https://invozone-backend.s3.amazonaws.com/django_5adea48424.svg",
        },
        {
          name: "Laravel",
          icon: "https://invozone-backend.s3.amazonaws.com/laravel_2c5aad2072.svg",
        },
        {
          name: "Java",
          icon: "https://invozone-backend.s3.amazonaws.com/java_187ba07902.svg",
        },
        {
          name: "ASP.NET",
          icon: "https://invozone-backend.s3.amazonaws.com/asp_dot_net_35b7ecd81c.svg",
        },
        {
          name: "Ruby",
          icon: "https://invozone-backend.s3.amazonaws.com/ruby_on_rails_f46192ca94.svg",
        },
        {
          name: ".NET Core",
          icon: "https://invozone-backend.s3.amazonaws.com/dot_net_core_a6f3b06d5e.svg",
        },
        {
          name: "Golang",
          icon: "https://invozone-backend.s3.amazonaws.com/golang_ff7b95ac01.svg",
        },
      ],
    },
  ]

  const [activeAccordion, setActiveAccordion] = useState(`${data[0]?.title}`)
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(0)
  return (
    <div className="techStacksOutSourcingMobile">
      <div className={styles.TechStacksMobileOutSourcingWeb}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Accordion defaultActiveKey={`${data[0]?.title}`}>
            {data &&
              data?.map((e, i) => (
                <Card className="" key={i}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${e?.title}`}
                    className={`TechStacksAccordOutSourcing ${
                      e?.title === activeAccordion ? "active-accordion" : ""
                    } ${
                      (i === 1 || i === 3 || i === 5) &&
                      e?.title !== activeAccordion
                        ? "second-accordion"
                        : (i === 0 || i === 2 || i === 4) &&
                          e?.title !== activeAccordion
                        ? "first-accordion"
                        : ""
                    }`}
                    onClick={() => setActiveAccordion(e?.title)}
                    style={
                      i === 1 && e?.title === activeAccordion
                        ? { marginTop: "24px" }
                        : {}
                    }
                  >
                    {e?.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${e?.title}`}>
                    <Card.Body className={`cardBody`}>
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Container>
      </div>
    </div>
  )
}

export default TechStacksMobileOutSource
