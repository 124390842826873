// extracted by mini-css-extract-plugin
export var TechStacksOutSourceWeb = "TechStacksOutSource-module--TechStacksOutSourceWeb--c8f98";
export var cardBg = "TechStacksOutSource-module--cardBg--12d54";
export var cards = "TechStacksOutSource-module--cards--aa6c9";
export var cardsContent = "TechStacksOutSource-module--cardsContent--8749c";
export var description = "TechStacksOutSource-module--description--fb548";
export var heading = "TechStacksOutSource-module--heading--88633";
export var iconssCard = "TechStacksOutSource-module--iconssCard--3ea3b";
export var nav = "TechStacksOutSource-module--nav--54a50";
export var navItem = "TechStacksOutSource-module--nav-item--9f821";
export var navbarBlock = "TechStacksOutSource-module--navbarBlock--edf3d";
export var tabData = "TechStacksOutSource-module--tabData--8a634";
export var tech = "TechStacksOutSource-module--tech--9c09b";
export var techIcon = "TechStacksOutSource-module--techIcon--51468";
export var techImg = "TechStacksOutSource-module--techImg--ca1e2";
export var technologyIcon = "TechStacksOutSource-module--technologyIcon--b8a34";